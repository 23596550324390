import React from "react";

import './ButtonBar.scss';

class ButtonBar extends React.Component {
    render()
    {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        )
    }
}

ButtonBar.defaultProps = {
    className: 'button-bar'
}

export {ButtonBar as default}
