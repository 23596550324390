import React from 'react';

import {ButtonReset} from "./Sounds";

import {RiDeleteBinLine} from "react-icons/ri";

class ResetButton extends React.Component {
    componentDidMount()
    {
        this.ResetSound = new Audio(ButtonReset);
    }

    onClick()
    {
        if (!this.props.isMuted) {
            this.ResetSound.play();
            this.props.onReset();
        }
    }

    render()
    {
        return (
            <button className="reset-button" onClick={() => this.onClick()}>
                <RiDeleteBinLine/>
            </button>
        );
    }
}

ResetButton.defaultProps = {
    isMuted: false,

    onReset: () => undefined
}

export {ResetButton as default};
