import React from 'react';

import {ButtonOff, ButtonOn} from "./Sounds";

import {RiPauseLine, RiPlayLine} from "react-icons/ri";

class PlayPauseButton extends React.Component {
    componentDidMount()
    {
        this.StartSound = new Audio(ButtonOn);
        this.StopSound = new Audio(ButtonOff);
    }

    pause()
    {
        this.props.onPause()
        this.props.onPlayStateToggle(false);

        if (!this.props.isMuted) {
            this.StopSound.play();
        }
    }

    play()
    {
        this.props.onPlay()
        this.props.onPlayStateToggle(true);

        if (!this.props.isMuted) {
            this.StartSound.play();
        }
    }

    onClick = (event) => {
        this.props.isPlaying ? this.pause() : this.play();

        event.preventDefault();
    }

    render()
    {
        return (
            <button className="play-pause-button" onClick={this.onClick}>
                {this.props.isPlaying ? <RiPauseLine/> : <RiPlayLine/>}
            </button>
        );
    }
}

PlayPauseButton.defaultProps = {
    isMuted  : false,
    isPlaying: false,

    onPause          : () => undefined,
    onPlay           : () => undefined,
    onPlayStateToggle: () => undefined
}

export {PlayPauseButton as default};
