import React from "react";
import TimeDisplay from "./TimeDisplay";

export default class Clock extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            date: new Date()
        }
    }

    componentDidMount()
    {
        this.TimerInterval = setInterval(() => this.tick(), 1000)
    }

    componentWillUnmount()
    {
        clearInterval(this.TimerInterval)
    }

    tick()
    {
        this.setState({
            date: new Date()
        })
    }

    render()
    {
        return (
            <TimeDisplay date={this.state.date} isMuted={this.props.isMuted}/>
        )
    }
}
