import React from "react";

import './Footer.scss';

import {RiHeartLine, RiReactjsFill} from "react-icons/ri";

class Footer extends React.Component {
    render()
    {
        return (
            <footer>
                <span>Made with</span>
                <span className="footer-icons">
                    &nbsp;
                    <RiHeartLine title="Love"/>
                    &nbsp;+&nbsp;
                    <RiReactjsFill title="React"/>
                    &nbsp;
                </span>
                <span>
                    by <a href="https://janwennrich.github.io" target="_blank" rel="noreferrer">Jan W.</a>
                </span>
            </footer>
        );
    }
}

export {Footer as default}
