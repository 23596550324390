import React from 'react';
import Clock from './components/Clock'
import './App.scss';
import Stopwatch from "./components/Stopwatch";
import MuteButton from "./components/MuteButton";
import Timer from "./components/Timer";
import Footer from "./components/Footer";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import {RiAlarmLine, RiTimeLine, RiTimerLine} from "react-icons/ri";
import {Alarm} from "./components/Sounds";

export default class App extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            nonMutedTab  : 0,
            isMuted      : 0,
            isAlarmActive: false
        }

        this.AlarmSound = new Audio(Alarm);
    }

    onTabChange = (index, lastIndex, event) => {
        this.setState({nonMutedTab: index});
    }

    onAlarm = () => {
        this.setAlarmOn();
        setTimeout(() => {
            this.setAlarmOff();
        }, 5000);
    }

    setAlarmOn()
    {
        this.setState({isAlarmActive: true});
        this.AlarmSound.play();
    }

    setAlarmOff()
    {
        this.setState({isAlarmActive: false});
    }

    render()
    {
        let state = this.state;

        return (
            <div className={`App ${this.state.isAlarmActive ? 'alarm-active' : ''}`}>
                <MuteButton className="global-mute-button"
                            onMuteStateToggle={(isMuted) => {
                                this.setState({isMuted: isMuted})
                            }}
                />
                <Tabs forceRenderTabPanel={true} className="time-tabs" onSelect={this.onTabChange}>
                    <TabPanel>
                        <Clock isMuted={state.isMuted || state.nonMutedTab !== 0}/>
                    </TabPanel>
                    <TabPanel>
                        <Stopwatch isMuted={state.isMuted || state.nonMutedTab !== 1}/>
                    </TabPanel>
                    <TabPanel>
                        <Timer isMuted={state.isMuted || state.nonMutedTab !== 2} onAlarm={this.onAlarm}/>
                    </TabPanel>

                    <TabList>
                        <Tab><RiTimeLine/></Tab>
                        <Tab><RiTimerLine/></Tab>
                        <Tab><RiAlarmLine/></Tab>
                    </TabList>
                </Tabs>
                <Footer/>
            </div>
        );
    }
}
