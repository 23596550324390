import React from "react";
import './TimeDisplay.scss';
import {Tac} from "./Sounds";

class TimeDisplay extends React.Component {
    componentDidMount()
    {
        this.TacSound = new Audio(Tac);
    }

    getParsedDate()
    {
        let hours = this.props.date.getHours().toString().padStart(2, '0');
        let minutes = this.props.date.getMinutes().toString().padStart(2, '0');
        let seconds = this.props.date.getSeconds().toString().padStart(2, '0');

        this.playSound();

        return `${hours}:${minutes}:${seconds}`;
    }

    async playSound()
    {
        if (this.TacSound && !this.props.isMuted) {
            this.TacSound.play();
        }
    }

    render()
    {
        return (
            <div className={this.props.className}>
                {this.getParsedDate()}
            </div>
        )
    }
}

TimeDisplay.defaultProps = {
    className: 'time-display'
};

export {TimeDisplay as default};
