import React from "react";

import ButtonBar from "./ButtonBar";
import TimeDisplay from "./TimeDisplay";
import PlayPauseButton from "./PlayPauseButton";
import ResetButton from "./ResetButton";

import './Timer.scss';

class Timer extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            date     : new Date(0, 0, 0, 0, 0, 0, 0),
            isRunning: false
        }
    }

    componentWillUnmount()
    {
        this.stop();
    }

    reset()
    {
        this.stop();

        this.setState({
            date: new Date(0, 0, 0, 0, 0, 0, 0)
        })
    }

    start()
    {
        if (this.state.isRunning) {
            return;
        }

        this.setState({
            isRunning: true
        }, () => {
            // We have to do this in the callback to make sure, that the state has been set properly
            this.tick(); // Execute it once immediately
            this.TimerInterval = setInterval(() => this.tick(), 1000)
        })
    }

    timerIsOver()
    {
        this.stop();
        this.props.onAlarm();
    }

    stop()
    {
        this.setState({
            isRunning: false
        });

        clearInterval(this.TimerInterval)
    }

    tick()
    {
        let time = this.state.date;

        time.setSeconds(time.getSeconds() - 1)

        if (time.getHours() === 0 && time.getMinutes() === 0 && time.getSeconds() === 0) {
            this.timerIsOver();
            return;
        }

        this.setState({
            date: time
        })
    }

    handleInputChange = (event) => {
        let date        = this.state.date,
            targetName  = event.target.name,
            targetValue = event.target.value;

        if (targetName === 'hours') {
            date.setHours(targetValue);
        }

        if (targetName === 'minutes') {
            date.setMinutes(targetValue)
        }

        if (targetName === 'seconds') {
            date.setSeconds(targetValue)
        }

        this.setState({date: date});
    }

    focusInputOnClick(event)
    {
        event.preventDefault();
        event.target.select()
    }

    TimeInput = (props) => {
        return (
            <input type="number"
                   onChange={this.handleInputChange}
                   onClick={this.focusInputOnClick}
                   min="0"
                   max="59"
                   width="2"
                   {...props}
            />
        )
    }

    TimeInputs()
    {
        return (
            <div className="time-inputs">
                <this.TimeInput name="hours" value={this.state.date.getHours().toString().padStart(2, '0')} max="23"/>
                :
                <this.TimeInput name="minutes" value={this.state.date.getMinutes().toString().padStart(2, '0')}/>
                :
                <this.TimeInput name="seconds" value={this.state.date.getSeconds().toString().padStart(2, '0')}/>
            </div>
        )
    }

    render()
    {
        return (
            <div className={this.props.className}>
                {
                    this.state.isRunning
                        ? <TimeDisplay date={this.state.date} isMuted={this.props.isMuted}/>
                        : this.TimeInputs()
                }

                <ButtonBar>
                    <PlayPauseButton
                        isPlaying={this.state.isRunning}
                        onPlay={() => this.start()}
                        onPause={() => this.stop()}
                    />
                    <ResetButton onReset={() => this.reset()}/>
                </ButtonBar>
            </div>
        )
    }
}

Timer.defaultProps = {
    className: 'timer',

    onAlarm: () => undefined
};

export {Timer as default};
