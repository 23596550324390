import React from "react";

import TimeDisplay from "./TimeDisplay";
import ButtonBar from "./ButtonBar";
import PlayPauseButton from "./PlayPauseButton";
import ResetButton from "./ResetButton";

class Stopwatch extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            date     : new Date(0, 0, 0, 0, 0, 0, 0),
            isRunning: false
        }
    }

    componentWillUnmount()
    {
        this.stop();
    }

    reset()
    {
        this.stop();

        this.setState({
            date: new Date(0, 0, 0, 0, 0, 0, 0)
        })
    }

    start()
    {
        if (this.state.isRunning) {
            return;
        }

        this.setState({
            isRunning: true
        });

        // Execute it once immediately
        this.tick();
        this.TimerInterval = setInterval(() => this.tick(), 1000)
    }

    stop()
    {
        clearInterval(this.TimerInterval)

        this.setState({
            isRunning: false
        });
    }

    tick()
    {
        let Time = this.state.date;

        Time.setSeconds(Time.getSeconds() + 1)

        this.setState({
            date: Time
        })
    }

    render()
    {
        return (
            <div className={this.props.className}>
                <TimeDisplay date={this.state.date} isMuted={this.props.isMuted}/>
                <ButtonBar>
                    <PlayPauseButton
                        isPlaying={this.state.isRunning}
                        onPlay={() => this.start()}
                        onPause={() => this.stop()}
                    />
                    <ResetButton onReset={() => this.reset()}/>
                </ButtonBar>
            </div>
        )
    }
}

Stopwatch.defaultProps = {
    className: 'stopwatch'
};

export {Stopwatch as default};
