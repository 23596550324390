import React from 'react';
import {RiVolumeMuteLine, RiVolumeUpLine} from "react-icons/ri";

class MuteButton extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {isMuted: false};
    }

    componentDidMount()
    {
        let isMuted = false;

        // null if not set
        let isMutedInStorage = localStorage.getItem(this.props.className + '.isMuted');

        if (this.props.useLocalStorage && isMutedInStorage !== null) {
            isMuted = isMutedInStorage === '1';
        }

        isMuted ? this.mute(false) : this.unmute(false);
    }

    mute(storeMuteStatus)
    {
        this.props.onMute()
        this.props.onMuteStateToggle(true);

        if (storeMuteStatus) {
            localStorage.setItem(this.props.className + '.isMuted', '1');
        }

        this.setState({isMuted: true});
    }

    unmute(storeMuteStatus)
    {
        this.props.onUnmute()
        this.props.onMuteStateToggle(false);

        if (storeMuteStatus) {
            localStorage.setItem(this.props.className + '.isMuted', '0');
        }

        this.setState({isMuted: false});
    }

    onClick = (event) => {
        let isMuted     = this.state.isMuted,
            storeStatus = this.props.useLocalStorage;

        isMuted ? this.unmute(storeStatus) : this.mute(storeStatus);

        event.preventDefault();
    }

    render()
    {
        return (
            <button className={this.props.className} onClick={this.onClick}>
                {this.state.isMuted ? <RiVolumeMuteLine/> : <RiVolumeUpLine/>}
            </button>
        );
    }
}

MuteButton.defaultProps = {
    className      : 'mute-button',
    useLocalStorage: true,

    onMute           : () => undefined,
    onUnmute         : () => undefined,
    onMuteStateToggle: () => undefined
}

export {MuteButton as default};
